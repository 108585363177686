import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'navbar',
  templateUrl: './navbar.component.html'
})
export class NavbarComponent implements OnInit {
  collapsed: boolean = true;

  constructor() { }

  ngOnInit(): void {
  }

  toggleNavbarCollapse() {
    this.collapsed = !this.collapsed;
  }

}

<h1>Projects</h1>

<div class="row">
    <div class="col-xl-6 mb-3" *ngFor="let project of getProjects()">
        <mat-card class="h-100">
            <mat-card-header>
                <mat-card-title>{{project.title}}</mat-card-title>
                <mat-card-subtitle *ngIf="project.subTitle">{{project.subTitle}}</mat-card-subtitle>
                <img *ngFor="let skill of project.skills" src="/assets/images/{{skill}}.png" class="skill-icon" matTooltip="{{skill | uppercase}}" />
            </mat-card-header>
            <img *ngIf="project.screenshot" mat-card-image src="/assets/images/{{project.screenshot}}" alt="{{project.title}} screenshot"/>
            <mat-card-content>
                <p class="text-justify">
                    {{project.description}}
                </p>
            </mat-card-content>
            <mat-card-actions>
                <a *ngIf="project.gitRepoUrl" mat-button href="{{project.gitRepoUrl}}" class="text-decoration-none" target="_blank">VIEW ON GITLAB</a>
                <a *ngIf="project.websiteUrl" mat-button href="{{project.websiteUrl}}" class="text-decoration-none" target="_blank">SEE IT LIVE</a>
            </mat-card-actions>
        </mat-card>
    </div>
</div>
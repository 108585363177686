<style>
    img.mat-card-image{
        min-height: 350px;
    }
</style>

<h1>Skills</h1>

<div class="row">
    <div class="col-xl-4 col-md-6 mb-3" *ngFor="let skill of getSkills()">
        <mat-card class="h-100">
            <mat-card-header>
                <div mat-card-avatar style="background-image: url('../../assets/images/{{skill.imgIcon}}'); background-size: cover;"></div>
                <mat-card-title>{{skill.title}}</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <p class="text-justify">
                    {{skill.description}}
                </p>
            </mat-card-content>
        </mat-card>
    </div>
</div>
import { DOCUMENT } from '@angular/common';
import { Component, HostListener, Inject, OnInit } from '@angular/core';

@Component({
  selector: 'scroll-to-top',
  template: `
    <button mat-mini-fab color="primary" aria-label="Scroll to top" type="button" class="scroll-to-top"
      [ngClass]="{ 'show-scroll-to-top': isWindowScrolled }" (click)="scrollToTop()">
      <i class="fas fa-angle-up"></i>
    </button>
  `,
})
export class ScrollToTopComponent implements OnInit {
  isWindowScrolled: boolean = false;

  @HostListener("window:scroll", [])
  onWindowScroll() {
    if (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop > 100) {
      this.isWindowScrolled = true;
    }
    else {
      this.isWindowScrolled = false;
    }
  }

  scrollToTop() {
    (function smoothScroll() {
      var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
      if (currentScroll > 0) {
        window.requestAnimationFrame(smoothScroll);
        window.scrollTo(0, currentScroll - (currentScroll / 8));
      }
    })();
  }

  ngOnInit(): void {
  }

}

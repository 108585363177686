<footer class="bg-light py-2">
    <div class="container d-flex justify-content-between align-items-center">
        <div class="text-black-50 text-monospace">
            &copy; Dimitar Benev {{currentDate | date: 'yyyy'}}
        </div>
        <div>
            <!-- <button mat-icon-button color="accent" matTooltip="Contact me" (click)="openContactFormDialog()"><i class="fas fa-envelope-square fa-lg"></i></button> -->
            <a mat-icon-button color="accent" matTooltip="LinkedIn" href="https://linkedin.com/in/dimitar-benev-87aa9b186" target="_blank"><i class="fab fa-linkedin fa-lg"></i></a>
            <a mat-icon-button color="accent" matTooltip="GitLab" href="https://gitlab.com/benevd" target="_blank"><i class="fab fa-gitlab fa-lg"></i></a>
        </div>
    </div>
</footer>
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {FormControl, Validators} from '@angular/forms';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html'
})
export class FooterComponent implements OnInit {
  currentDate = new Date();

  constructor(public dialog: MatDialog) { }

  ngOnInit(): void {
  }

  openContactFormDialog(){
    this.dialog.open(ContactFormComponent, {width: '400px' });
  }
}

@Component({
  templateUrl: './contact-form.component.html'
})
export class ContactFormComponent{
  email = new FormControl('', [Validators.required, Validators.email]);
  message = new FormControl('', [Validators.required]);

  getEmailErrorMessage() {
    if (this.email.hasError('required')) {
      return 'Your email address is required so I can get back to you.';
    }

    return this.email.hasError('email') ? 'Not a valid email.' : '';
  }
}
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ISkill } from './skill.model';

@Component({
  templateUrl: './skills.component.html',
})
export class SkillsComponent implements OnInit {

  constructor(private titleService: Title) {
    this.titleService.setTitle("Skills - Dimitar Benev")
  }

  ngOnInit(): void {
  }

  getSkills() {
    return SKILLS;
  }

  openProjectsWithFilter(filter: string) {

  }

}

const SKILLS: ISkill[] = [
  { title: "C#", imgIcon: "csharp.png", description: "One of my favourites and probably the most versatile language I know. I have used C# extensively in different contexts, whether it be for Web API or ASP.NET Back Ends and even WPF desktop apps. It is starting to pick up steam for Front End purposes now too! (see Blazor)" },
  { title: ".NET", imgIcon: "dotnet.png", description: "Together with C#, this framework has been at the foundation of all sorts of applications I have developped over the years, notably with MVC." },
  { title: "HTML, CSS and JavaScript", imgIcon: "htmlcssjavascript.png", description: "The big three of web development, no website is whole without them. So whether they are used natively or through frontend frameworks, as someone who has done a lot of web development, they are at the core of nearly all my projects." },
  { title: "Angular", imgIcon: "angular.png", description: "Modern and great for building Single Page Applications, Angular has quickly become my frontend frameork of choice for new web application projects. Choosing it for my personal portfolio is no coincidence :)" },
  { title: "React", imgIcon: "react.png", description: "Another awesome way of creating Single Page Applications, it was my first experience with a front-end JS framework. Although I haven't used it as much as Angular, I am still very much interested in learning more about it." },
  { title: "jQuery", imgIcon: "jquery.png", description: "jQuery greatly simplifies vanilla JS and provides convenient shortcuts to its functionality. Although not as widely used anymore, my past projects have benefited a lot from it." },
  { title: "Sass", imgIcon: "sass.png", description: "Sass makes CSS and frontend development so much more enjoyable. Similarly to jQuery, I like the convenience its feature set brings." },
  { title: "Java", imgIcon: "java.png", description: "As my introduction to object-oriented programming, I learned a lot of what I know today through Java. I've coded various applications in Java for different platforms - desktop (Maven + JavaFX project), mobile (Android) and web (JSF & Spring Boot)." },
  { title: "Spring Boot", imgIcon: "spring.png", description: "I have developed many Back End REST APIs in Java using the Spring Boot framework. It offers many convenient features to build a robust API." },
  { title: "SQL", imgIcon: "sql.png", description: "SQL has been at the core of my entire development career. I've used many different flavours of it (Microsoft SQL Server, MySQL, Postgres SQL, Oracle SQL) as well as different frameworks such as LINQ in the Entity Framework for .NET projects and JPA and Hibernate in the Spring framework." },
  { title: "php", imgIcon: "php.png", description: "I have used php in a few school projects, one being a chat room and the other was a Back End REST API built with the Laravel framework that was used in conjunction with an Android application that submitted and fetched data." },
  { title: "Bootstrap", imgIcon: "bootstrap.png", description: "Bootstrap is great for rapidly styling your website and has a ton of useful utilities and components that are really intuitive. My personal favourite is the grid system." },
  { title: "Photoshop", imgIcon: "photoshop.png", description: "Photoshop is my go-to image editing software, whether it be for development projects assets or for personal graphic design gigs." },
  { title: "Android", imgIcon: "android.png", description: "I've developed a few Android applications with Java, you can check some of them out in my list of projects." },
];
<nav class="navbar navbar-expand-md navbar-light">
    <div class="container align-items-baseline">
        <div>
            <a class="navbar-brand pb-0" href="#">
                Dimitar Benev</a>
            <div class="text-muted">Full Stack Developer</div>
        </div>
        <button mat-icon-button class="navbar-toggler hamburger hamburger--spin " type="button" data-toggle="offcanvas"
            (click)="toggleNavbarCollapse()" [ngClass]="{ 'is-active': !collapsed }" aria-expanded="false"
            aria-controls="mobileNav" aria-label="Toggle navigation">
            <span class="hamburger-box">
                <span class="hamburger-inner"></span>
            </span>
        </button>
        <div class="navbar-collapse justify-content-end" class="d-none d-md-flex" id="navbarNav">
            <ul class="navbar-nav">
                <li class="nav-item">
                    <a mat-button color="primary" [routerLink]="['/']" routerLinkActive="active"
                        [routerLinkActiveOptions]="{exact:true}">Home</a>
                </li>
                <li class="nav-item">
                    <a mat-button color="primary" [routerLink]="['/projects']" routerLinkActive="active">Projects</a>
                </li>
                <li class="nav-item">
                    <a mat-button color="primary" [routerLink]="['/skills']" routerLinkActive="active">Skills</a>
                </li>
                <li class="nav-item">
                    <a mat-button color="primary" [routerLink]="['/about-me']" routerLinkActive="active">About me</a>
                </li>
            </ul>
        </div>

        <div class="navbar-collapse offcanvas-collapse justify-content-end" id="mobileNav"
            [ngClass]="{ open: !collapsed }">
            <ul class="navbar-nav">
                <li class="nav-item">
                    <a mat-button color="primary" (click)="toggleNavbarCollapse()" [routerLink]="['/']"
                        routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">Home</a>
                </li>
                <li class="nav-item">
                    <a mat-button color="primary" (click)="toggleNavbarCollapse()" [routerLink]="['/projects']"
                        routerLinkActive="active">Projects</a>
                </li>
                <li class="nav-item">
                    <a mat-button color="primary" (click)="toggleNavbarCollapse()" [routerLink]="['/skills']"
                        routerLinkActive="active">Skills</a>
                </li>
                <li class="nav-item">
                    <a mat-button color="primary" (click)="toggleNavbarCollapse()" [routerLink]="['/about-me']"
                        routerLinkActive="active">About me</a>
                </li>
            </ul>
        </div>
    </div>
</nav>
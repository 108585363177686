<h1 mat-dialog-title>Contact me</h1>
<div mat-dialog-content>
    <form>
        <p>
            <mat-form-field appearance="standard">
                <mat-label>Your email address</mat-label>
                <input matInput placeholder="name@example.com" [formControl]="email" required>
                <mat-error *ngIf="email.invalid">{{getEmailErrorMessage()}}</mat-error>
            </mat-form-field>
        </p>
        <p>
            <mat-form-field appearance="standard">
                <mat-label>Message</mat-label>
                <textarea matInput placeholder="Your message" [formControl]="message" required></textarea>
                <mat-error *ngIf="message.invalid">You need to enter a message</mat-error>
            </mat-form-field>
        </p>
    </form>
</div>
<div mat-dialog-actions>
    <button mat-button color="primary">Submit</button>
    <button mat-button mat-dialog-close>Close</button>
</div>
<div class="d-block text-center">
    <img src="../../assets/images/Dimitar.png" class="d-block m-auto portrait" />
    <h1>About me</h1>
</div>

<p class="lead text-justify">
    I'm a developer based in Montreal, Canada. I've been programming professionally since I graduated from Computer Science Technology program at Dawson College in 2019.
</p>

<p class="lead text-justify">
    As part of doing Full Stack development, I have lots of experience working with Front End UI/UX, as well as Back End systems such as REST APIs and SQL databases.
</p>

<p class="lead text-justify">
    Other than programming, my hobbies include travelling, gaming, PC building, hiking, cycling, photography and design.
</p>

<p class="lead text-justify">
    I am also completely fluent in 3 languages: English, French and Bulgarian.
</p>
import { Component, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { ScrollToTopComponent } from './scroll-to-top/scroll-to-top.component';

@Component({
  selector: 'portfolio-app',
  template: `
    <navbar></navbar>
    <div class="container flex-fill">
      <router-outlet></router-outlet>
    </div>
    <app-footer></app-footer>
    <scroll-to-top></scroll-to-top>
  `,
})
export class PortfolioAppComponent {
  @ViewChild(ScrollToTopComponent) scrollToTop: ScrollToTopComponent;
  title = 'my-portfolio';

  constructor(private router : Router) {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((navigationEndEvent: NavigationEnd) => {
      this.scrollToTop.scrollToTop(); // Always make sure we are scrolling to the top when routing
    });
   }
}

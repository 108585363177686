import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { IProject } from './project.model';

@Component({
  templateUrl: './projects.component.html',
})
export class ProjectsComponent implements OnInit {

  constructor(private titleService: Title) {
    this.titleService.setTitle("Projects - Dimitar Benev")
   }

  ngOnInit(): void {
  }

  getProjects(){
    return PROJECTS;
  }

}

const PROJECTS: IProject[] = [
  { title: "My Portfolio", screenshot: 'portfolio-angular.png', description: "This is the website that you are on right now! It is a Front End project built in Angular.", skills: ["angular", "html", "css", "javascript", "sass"] },
  { title: "RDP Consultants website", screenshot: 'rdp-website-mvc.png', description: "Informational website for the RDP consultants firm.", skills: ["dotnet", "csharp", "html", "css", "javascript", "sass"], websiteUrl: "https://www.rdpcorp.com/" },
  { title: "Desktop Gmail application", screenshot: 'java-mail-app.png', description: "A desktop client for Gmail that supports receiving and sending emails, made entirely in Java (including the UI, it is using Java FX!).", gitRepoUrl: "https://gitlab.com/benevd/Java_Mail_App", skills: ["java", "sql"] },
  { title: "Sliding puzzle game", screenshot: 'sliding-puzzle-js.png', description: "A kids-themed sliding puzzle game coded entirely in vanilla HTML, CSS and JavaScript from scratch.", gitRepoUrl: "https://gitlab.com/benevd/javascript-sliding-puzzle-game", skills: ["html", "css", "javascript"] },
  { title: "Brewery search", screenshot: 'brewery-browse-react.png', description: "My first React project, this app was great for practicing my newly acquired knowledge. It fetches US brewery data from an external API and displays it in a list view that is user searchable.", gitRepoUrl: "https://gitlab.com/benevd/brewery-search", skills: ["react", "html", "css", "javascript"] },
  { title: "Tic-Tac-Toe game app", screenshot: 'tic-tac-toe.png', description: "Tic-Tac-Toe Android application for playing against an AI or another player.", gitRepoUrl: "https://gitlab.com/benevd/tic-tac-toe-app", skills: ["android", "java"] },
  { title: "Image editing library", screenshot: 'image-na.png', description: "This small class library coded in C# provides utilities for editing images such as cropping, flipping, etc. This project also includes unit tests.", gitRepoUrl: "https://gitlab.com/benevd/csharp-image-manipulation-library", skills: ["csharp"] },
  { title: "Q&A trivia app", screenshot: 'image-na.png', description: "Q&A Android app with various trivia questions. All questions and images were stored and fetched from a Google Firebase database.", gitRepoUrl: "https://gitlab.com/benevd/qna-android-trivia", skills: ["android", "java"] },
]
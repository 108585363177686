<div class="row">
    <div class="col-xl-5 d-flex align-items-center">
        <div>
            <h1 class="font-weight-bold">Hello!</h1>
            <h1 class="font-weight-bold">I'm Dimitar.</h1>
            <h2 class="font-weight-bold">I'm a Full Stack Developer.</h2>
            <p class="lead text-justify">
                I'm a big picture thinker. I like to connect the dots and help find solutions to problems.
                I accomplish this by using the latest technologies to design and develop intuitive applications that
                matter and that people love to use.
            </p>
        </div>
    </div>
    <div class="col-xl-7">
        <div id="my-carousel" class="carousel no-arrows slide carousel-fade" data-ride="carousel" data-interval="5000">
            <ol class="carousel-indicators d-none d-md-flex">
                <li data-target="#my-carousel" data-slide-to="0" class="active"></li>
                <li data-target="#my-carousel" data-slide-to="1"></li>
                <li data-target="#my-carousel" data-slide-to="2"></li>
            </ol>

            <div class="carousel-inner">
                <div class="carousel-item active">
                    <img class="d-block w-100 rounded" src="/assets/images/also-me.png" alt="First">
                    <div class="carousel-caption">
                        <h5>About me</h5>
                        <p>Programming professionally since 2019. Visit the About me section for more.</p>
                      </div>
                </div>

                <div class="carousel-item">
                    <img class="d-block w-100 rounded" src="/assets/images/programming.jpg" alt="Second slide">
                    <div class="carousel-caption">
                        <h5>My projects & skills</h5>
                        <p>Check out the Projects and Skills sections for details.</p>
                      </div>
                </div>

                <div class="carousel-item">
                    <img class="d-block w-100 rounded" src="/assets/images/project.png" alt="Third slide">
                    <div class="carousel-caption">
                        <h5 class="text-primary">Have a project idea?</h5>
                        <p class="text-primary">You can reach out to me via LinkedIn for any business inquiries.</p>
                      </div>
                </div>
            </div>
            <!-- <a class="carousel-control-prev" href="#my-carousel" role="button" data-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="sr-only">Previous</span>
            </a>
            <a class="carousel-control-next" href="#my-carousel" role="button" data-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="sr-only">Next</span>
            </a> -->
        </div>
    </div>
</div>

<div class="row mt-5">
    <div class="col-lg-4 border-right d-flex flex-column">
        <div class="w-100 text-center">
            <i class="fas fa-tasks fa-5x text-primary mb-2"></i>
            <h3>Projects</h3>
        </div>
        <div class="d-flex justify-content-between flex-column flex-grow-1">
            <p class="lead text-justify">
                Take a look at some of the projects that I've worked on that helped me consolidate my skills.
            </p>
            <a mat-button class="text-decoration-none" [routerLink]="['/projects']">
                <i class="fas fa-arrow-alt-circle-right mr-2"></i>
                Take me there
            </a>
        </div>
    </div>

    <div class="col-lg-4 border-right d-flex flex-column">
        <div class="w-100 text-center">
            <i class="fas fa-book-open fa-5x text-primary mb-2"></i>
            <h3>Skills</h3>
        </div>
        <div class="d-flex justify-content-between flex-column flex-grow-1">
            <p class="lead text-justify">
                Curious about all of the various technologies I've worked with? They are all listed here.
            </p>
            <a mat-button class="text-decoration-none" [routerLink]="['/skills']">
                <i class="fas fa-arrow-alt-circle-right mr-2"></i>
                Take me there
            </a>
        </div>
    </div>

    <div class="col-lg-4 d-flex flex-column">
        <div class="w-100 text-center">
            <i class="fas fa-address-card fa-5x text-primary mb-2"></i>
            <h3>About me</h3>
        </div>
        <div class="d-flex justify-content-between flex-column flex-grow-1">
            <p class="lead text-justify">
                Learn more about the person behind the projects.
            </p>
            <a mat-button class="text-decoration-none" [routerLink]="['/about-me']">
                <i class="fas fa-arrow-alt-circle-right mr-2"></i>
                Take me there
            </a>
        </div>
    </div>
</div>
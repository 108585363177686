import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  templateUrl: './about-me.component.html',
})
export class AboutMeComponent implements OnInit {

  constructor(private titleService: Title) {
    this.titleService.setTitle("About me - Dimitar Benev")
   }

  ngOnInit(): void {
  }

}
